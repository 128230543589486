import { useCallback, useEffect, useRef } from 'react'

import { Accordion, AccordionItem, Button, Divider, Icon, Text } from '@cash-me/react-components'
import NextLink from 'next/link'
import { useRouter } from 'next/router'

import { Logo } from 'components/logo'
import useClickOutside from 'hooks/useClickOutside'
import { pushDataLayer } from 'utils/push-data-layer'

import { SidebarProps } from './sidebar.model'
import * as S from './styles'

export const Sidebar = ({ showSidebar, close }: SidebarProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const router = useRouter()

  const handleDataLayer = useCallback((section: string, name: string) => {
    pushDataLayer({
      category: 'Clique',
      action: 'Header',
      label: `${section} - Link ${name}`
    })
  }, [])

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault()

        close()
      }
    },
    [close]
  )

  const handleClickOutside = useCallback(() => {
    close()
  }, [close])

  useClickOutside(ref, handleClickOutside)

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp, false)

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false)
      close()
    }
  }, [handleKeyUp, close])

  return (
    <S.Wrapper data-show={showSidebar} aria-hidden={showSidebar}>
      <S.Content ref={ref}>
        <S.CloseButton aria-label="Fechar menu lateral" onClick={close}>
          <Icon name="close" color="var(--colors-blue-text-strong)" size={28} />
        </S.CloseButton>
        <S.Menu>
          <S.ButtonsWrapper>
            <Button
              size="sm"
              color="primary"
              variants="text-only"
              onClick={() => {
                handleDataLayer('Sou cliente', 'Sou cliente')
                location.href = '/sou-cliente'
              }}
            >
              Sou cliente
            </Button>
            <Button
              size="sm"
              color="secondary"
              variants="text-only"
              onClick={() => {
                handleDataLayer('Sou parceiro', 'Sou parceiro')
                const { asPath } = router
                const url = asPath.includes('/condo/')
                  ? process.env.NEXT_PUBLIC_CONDO_URL
                  : process.env.NEXT_PUBLIC_CASHMEMBER_URL

                window.open(url)
              }}
            >
              Sou parceiro
            </Button>
          </S.ButtonsWrapper>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Seja nosso parceiro
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Ofereça valor para seus clientes
            </Text>
            <NextLink
              href="/seja-um-parceiro"
              onClick={() => {
                handleDataLayer('Seja parceiro', 'Seja parceiro')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Seja nosso parceiro
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <Divider />
          <Accordion type="multiple" className="accordion-support">
            <AccordionItem
              id="sidebar-0"
              className="accordion-item"
              title={
                <Text size="heading4" weight="medium">
                  <Icon name="loan" color="var(--colors-cyan-icon-default)" size={24} /> Para você
                </Text>
              }
            >
              <S.AccordionLinks>
                <NextLink
                  href="/emprestimo-com-garantia-de-imovel"
                  onClick={() => {
                    handleDataLayer('Para você', 'Empréstimo com Garantia de Imóvel')
                  }}
                  className="ds-link"
                >
                  Empréstimo com garantia de imóvel
                </NextLink>
                <NextLink
                  href="/consorcio/"
                  onClick={() => {
                    handleDataLayer('Para você', 'Consórcio')
                  }}
                  className="ds-link"
                >
                  Consórcio
                </NextLink>
                <NextLink
                  href="/financiamento-energia-solar"
                  onClick={() => {
                    handleDataLayer('Para você', 'Financiamento para Energia Solar')
                  }}
                  className="ds-link"
                >
                  Financiamento para Energia Solar
                </NextLink>
                <NextLink
                  href="/financiamento-imobiliario"
                  onClick={() => {
                    handleDataLayer('Para você', 'Financiamento Imobiliário')
                  }}
                  className="ds-link"
                >
                  Financiamento Imobiliário
                </NextLink>
                <NextLink
                  href="/financiamento-para-construcao"
                  onClick={() => {
                    handleDataLayer('Para você', 'Financiamento para Construção')
                  }}
                  className="ds-link"
                >
                  Financiamento para Construção
                </NextLink>
              </S.AccordionLinks>
            </AccordionItem>
            <AccordionItem
              id="sidebar-1"
              className="accordion-item"
              title={
                <Text size="heading4" weight="medium">
                  <Logo type="IconBusiness" width={1.5} height={1.5} className="business-icon" /> Para seu negócio
                </Text>
              }
            >
              <S.AccordionLinks>
                <NextLink
                  href="/emprestimo-com-garantia-de-imovel"
                  onClick={() => {
                    handleDataLayer('Para seu negócio', 'Empréstimo com Garantia de Imóvel')
                  }}
                  className="ds-link"
                >
                  Empréstimo com garantia de imóvel
                </NextLink>
                <NextLink
                  href="/consorcio/"
                  onClick={() => {
                    handleDataLayer('Para seu negócio', 'Consórcio')
                  }}
                  className="ds-link"
                >
                  Consórcio
                </NextLink>
                <NextLink
                  href="/financiamento-para-construcao"
                  onClick={() => {
                    handleDataLayer('Para você', 'Financiamento para Construção')
                  }}
                  className="ds-link"
                >
                  Financiamento para Construção
                </NextLink>
                <NextLink
                  href="/financiamento-energia-solar"
                  onClick={() => {
                    handleDataLayer('Para seu negócio', 'Financiamento para Energia Solar')
                  }}
                  className="ds-link"
                >
                  Financiamento para Energia Solar
                </NextLink>
              </S.AccordionLinks>
            </AccordionItem>
            <AccordionItem
              id="sidebar-2"
              className="accordion-item"
              title={
                <Text size="heading4" weight="medium">
                  <Icon name="apartment" color="var(--colors-cyan-icon-default)" size={24} /> Para seu condomínio
                </Text>
              }
            >
              <S.AccordionLinks>
                <NextLink
                  href="/condo"
                  onClick={() => {
                    handleDataLayer('Para seu condomínio', 'Para seu condomínio')
                  }}
                  className="ds-link"
                >
                  Empréstimo para condomínio
                </NextLink>
                <NextLink
                  href="/financiamento-energia-solar"
                  onClick={() => {
                    handleDataLayer('Para seu condomínio', 'Financiamento para Energia Solar')
                  }}
                  className="ds-link"
                >
                  Financiamento para Energia Solar
                </NextLink>
              </S.AccordionLinks>
            </AccordionItem>
            <AccordionItem
              id="sidebar-3"
              className="accordion-item"
              title={
                <Text size="heading4" weight="medium">
                  <Icon name="garage" color="var(--colors-cyan-icon-default)" size={24} /> Consórcio
                </Text>
              }
            >
              <S.AccordionLinks>
                <NextLink
                  href="/consorcio/"
                  onClick={() => {
                    handleDataLayer('Consórcio', 'Imóveis e automóveis')
                  }}
                  className="ds-link"
                >
                  Imóveis e automóveis
                </NextLink>
              </S.AccordionLinks>
            </AccordionItem>
          </Accordion>
          <Divider className="divider" />
          <Text size="caption" weight="bold" uppercase color="var(--colors-neutral-text-weaker)">
            A CashMe
          </Text>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Sobre a CashMe
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Conheça a gente melhor
            </Text>
            <NextLink
              href="/quem-somos"
              onClick={() => {
                handleDataLayer('A CashMe', 'Sobre a CashMe')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Fale com a gente
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Conheça nossos canais oficiais de atendimento
            </Text>
            <NextLink
              href="/fale-conosco"
              onClick={() => {
                handleDataLayer('A CashMe', 'Fale com a gente')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Blog CashMe
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Dicas sobre educação financeira
            </Text>
            <NextLink
              href="https://www.cashme.com.br/blog/"
              onClick={() => {
                handleDataLayer('A CashMe', 'Blog')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Segurança
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Veja como denunciar golpes e fraudes e aprenda a reconhecer os tipos mais comuns.
            </Text>
            <NextLink
              href="/seguranca"
              onClick={() => {
                handleDataLayer('A CashMe', 'Segurança')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              ConstruCast
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Fique por dentro do podcast que fala sobre as novidades do mercado imobiliário
            </Text>
            <NextLink
              href="https://www.cashme.com.br/construcast/"
              onClick={() => {
                handleDataLayer('A CashMe', 'Construcast')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Programa de Integridade
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Conheça os princípios que orientam nossa conduta.
            </Text>
            <NextLink
              href="/programa-de-integridade"
              onClick={() => {
                handleDataLayer('A CashMe', 'Programa de Integridade')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <Divider className="divider" />
          <Text size="caption" weight="bold" uppercase color="var(--colors-neutral-text-weaker)">
            Ajuda
          </Text>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Fale com a gente
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              A nossa equipe está pronta para te ajudar
            </Text>
            <NextLink
              href="/fale-conosco"
              onClick={() => {
                handleDataLayer('Ajuda', 'Fale com a gente')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
          <S.SidebarItem>
            <Text size="heading4" weight="medium">
              Precisa de ajuda?
            </Text>
            <Text size="body2" color="var(--colors-neutral-text-weaker)">
              Veja quais dúvidas outras pessoas também tiveram
            </Text>
            <NextLink
              href="/ajuda"
              onClick={() => {
                handleDataLayer('Ajuda', 'Ajuda')
              }}
            >
              <Text size="body2" color="var(--colors-blue-text-default)">
                Saiba mais
              </Text>
              <Icon name="arrowRight" size={12} />
            </NextLink>
          </S.SidebarItem>
        </S.Menu>
      </S.Content>
    </S.Wrapper>
  )
}
